import React from 'react'
import { Link } from 'gatsby'
import { intl } from 'src/constants'

export default ({ novels }) => {
  if (novels.every(novel => novel.imgList.length === 0)) return null
  return (
    <section className="garelly">
      <h3>{intl.getMessage('illustration_gallery')}</h3>
      <div className="illust_gallery">
        {novels.map(novel =>
          novel.imgList.map((imgInfo, idx) => (
            <div className="illust" key={`${novel.slug}_${idx}`}>
              <Link to={`/novel/${novel.slug}/${idx + 1}`}>
                <img lazyload src={imgInfo.url}></img>
              </Link>
            </div>
          ))
        )}
      </div>
    </section>
  )
}

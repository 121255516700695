import React from 'react'

export default ({ characters }) => (
  <div className="character_introductions">
    {characters.map((character, index) => (
      <div key={index} className="character_introduction">
        <div className="flex">
          <div className="left">
            <img lazyload src={character.image.sourceUrl}></img>
          </div>
          <div className="right">
            <h4>{character.name}</h4>
            <div className="profile">{character.introduction}</div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

import React from 'react'
import ArticleHeader from 'components/ArticleHeader'
import IllustGallery from 'components/IllustGallery'
import { stripOfficial } from 'src/commonFunctions'
import ArticleCover from 'components/ArticleCover'
import ChapterCard from 'components/ChapterCard'
import InnerHtml from 'components/atoms/InnerHtml'
import CharacterIntroduction from 'components/CharacterIntroduction'
import uuid from 'uuid/v4'
import EmbedContainer from 'react-oembed-container'
import { intl } from 'src/constants'

export default ({
  date,
  index,
  genre,
  novels,
  title,
  isOfficial,
  cover,
  sharePath,
}) => (
  <article className="index_novel_article">
    <ArticleHeader
      date={date}
      isOfficial={stripOfficial(isOfficial)}
      genre={genre}
    />
    <ArticleCover
      backgroundStyle={{ backgroundImage: `url(${cover.sourceUrl})` }}
      title={title}
      authors={index.authors}
      item={index.item}
      sharePath={sharePath}
      isIndex
    />
    <section className="about">
      <h3>{intl.getMessage('synopsis')}</h3>
      <EmbedContainer markup={index.about}>
        <InnerHtml className="about_text" html={index.about} />
      </EmbedContainer>
    </section>
    {index.characters && index.characters.length > 0 && (
      <section className="characters">
        <h3>{intl.getMessage('character_introduction')}</h3>
        <CharacterIntroduction characters={index.characters} />
      </section>
    )}
    <section className="index">
      <h3>{intl.getMessage('table_of_contents')}</h3>
      {index.item.map(node => (
        <ChapterCard
          key={uuid()}
          url={node.link ? node.link.url : null}
          title={node.title}
          date={node.date}
          genre={genre}
        />
      ))}
    </section>
    <IllustGallery novels={novels} />
  </article>
)

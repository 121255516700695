import React from 'react'
import { Link } from 'gatsby'
import { convertUrlToInnerLink } from 'src/commonFunctions'
import { intl } from 'src/constants'

export default ({ item }) => (
  <section className="read_buttons">
    <StartReadButton item={item} />
    <StartReadButton item={item} isLatest />
  </section>
)

const StartReadButton = ({ item, isLatest = false }) => {
  const content = isLatest ? getLatestChapter(item) : item[0]
  if (content && content.link) {
    return (
      <Link
        to={convertUrlToInnerLink(content.link.url)}
        className={`read ${isLatest ? 'latest' : 'start'}`}
      >
        <div className="label">
          {isLatest
            ? intl.getMessage('read_the_latest_story')
            : intl.getMessage('read_from_the_beginning')}
        </div>
        <div className="title">{content.title}</div>
        <div className="date">{content.date}</div>
      </Link>
    )
  } else {
    return null
  }
}

const getLatestChapter = item => item.filter(i => i.link !== null).pop()

import React from 'react'
import Helmet from 'react-helmet'
import { SITE_TITLE, MENUS } from 'src/constants'
import ArticleLayout from 'components/ArticleLayout'
import IndexNovelArticle from 'components/IndexNovelArticle'
import Footer from 'components/Footer'

export default ({ pageContext }) => {
  const {
    type,
    articleBaseInfo,
    recommendArticles,
    indexCover,
    novelsWithImages,
    sharePath,
    index,
    adbanners,
  } = pageContext
  const { title, tags, ogpSettings, isOfficial } = articleBaseInfo

  return (
    <>
      <Helmet title={`${title} | ${SITE_TITLE}`} />
      <ArticleLayout
        type={type}
        articleBaseInfo={articleBaseInfo}
        recommendArticles={recommendArticles}
        sharePath={sharePath}
        adbanners={adbanners}
      >
        <IndexNovelArticle
          genre={MENUS.SERIES}
          title={title}
          tags={tags}
          date={articleBaseInfo.modified}
          ogp={ogpSettings.ogp}
          cover={indexCover.cover}
          index={index}
          novels={novelsWithImages}
          isOfficial={isOfficial}
          sharePath={sharePath}
        />
      </ArticleLayout>
      <Footer tags={pageContext.tags} />
    </>
  )
}

import React from 'react'
import ReadButtons from 'components/atoms/ReadButtons'
import ShareContent from 'components/ShareContent'
import { intl } from 'src/constants'

export default class ArticleCover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCovered: true,
    }
  }
  toggleCover = () => {
    this.setState({
      isCovered: !this.state.isCovered,
    })
  }

  render() {
    const {
      backgroundStyle,
      title,
      authors,
      chapter,
      isIndex,
      isComic,
      item,
      linkToComicWalker,
      linkToNiconico,
      sharePath,
    } = this.props
    return (
      <section
        className="cover"
        style={backgroundStyle}
        onClick={this.toggleCover}
      >
        <div
          className={`header ${
            this.state.isCovered ? 'is-covered' : 'is-revealed'
          }`}
        >
          <h2 className="title">{title}</h2>
          <div className="share_wrapper">
            <ShareContent title={title} sharePath={sharePath} />
          </div>
          {authors &&
            authors.length > 0 &&
            authors.map(author => (
              <section className="author">
                <div className="author_label">{author.role}</div>
                <h2>{author.name}</h2>
              </section>
            ))}
          {chapter && (
            <section className="chapter">
              <h2>{chapter}</h2>
            </section>
          )}
          {isIndex && <ReadButtons item={item} />}
          {isComic && linkToComicWalker && (
            <a
              className="comic-walker"
              href={linkToComicWalker}
              target="_blank"
              rel="noopener"
            >
              {intl.getMessage('read_on_comic_walker')}
            </a>
          )}
          {isComic && linkToNiconico && (
            <a
              className="comic-walker"
              href={linkToNiconico}
              target="_blank"
              rel="noopener"
            >
              {intl.getMessage('read_on_comic_niconico')}
            </a>
          )}
        </div>
      </section>
    )
  }
}

ArticleCover.defaultProps = {
  backgroundStyle: {},
  chapter: '',
  item: [],
  author: false,
  isIndex: false,
  isComic: false,
  linkToComicWalker: null,
}
